import React from "react";
import { useNavigate } from "react-router-dom";
import "./Tryout.css";

function Tryout() {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/Registration");
  };

  return (
    <div className="TryoutContainer">
      <div className="tryout-content">
        <p>سجل خلال لحظات، وابدأ بتجربة سريعة ومباشرة!</p>
        <div className="tryout-buttons">
          <button className="get-started-button" onClick={handleGetStarted}>
            ابدأ الآن
          </button>
        </div>
      </div>
    </div>
  );
}

export default Tryout;
