import { makeRequest } from "../core/data-access/http.service"; // Adjust the import path based on your project structure
import qs from "qs";
/**
 * Fetch previous payment links
 * @param {string} link_url - The URL parameter for the payment link resolver
 * @returns {Promise} Axios response with the list of previous links
 */
export const fetchResolverPaymentLinks = async (link_url) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/endUser/payment-link-resolver/fetch-link-content`;

  try {
    const response = await makeRequest({
      url,
      method: "GET",
      params: { link_url: `cora.sa/${link_url}` },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false });
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching payment links:", error);
    throw error;
  }
};
