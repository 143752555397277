import { makeRequest } from "../core/data-access/http.service"; // Adjust the import path based on your project structure

/**
 * Send OTP to the specified phone number
 * @param {string} phoneNumber - The phone number to send the OTP to
 * @param {string} otpType - The type of OTP (optional, based on your backend requirements)
 * @returns {Promise} Axios response
 */
export const sendOtp = async (phoneNumber, otpType = "string") => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/authentication/send-otp`;

  try {
    const response = await makeRequest({
      url,
      method: "POST",
      data: {
        phoneNumber,
        otpType,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
