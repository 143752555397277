import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp < currentTime; // Token is expired if expiration time is less than current time
  } catch (error) {
    console.error("Error decoding token:", error);
    return true; // Treat token as expired if decoding fails
  }
};
// Create an axios instance
const axiosObject = axios.create();

axiosObject.interceptors.request.use(
  async function (config) {
    // Add token to headers if available

    const token = Cookies.get("authToken");
    if (token) {
      if (isTokenExpired(token)) {
        Cookies.remove("authToken", { domain: ".cora.sa", path: "/" });
        localStorage.clear();
        window.location.href = "/login"; // Redirect to login page
        throw new axios.Cancel("Token expired");
      }
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  function (error) {
    console.error("Request error: ", error);
    return Promise.reject(error);
  }
);

axiosObject.interceptors.response.use(
  function (response) {
    // Handle successful responses
    return response;
  },
  function (error) {
    console.error("Response error: ", error);
    if (error.response?.status === 401) {
      // If token is invalid, clear cookies and redirect to login
      Cookies.remove("authToken", { domain: ".cora.sa", path: "/" });
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default axiosObject;
