import React, { useState } from 'react';
import './Faq.css'; // Import the CSS file for styling
import { Helmet } from 'react-helmet';

const faqData = [
    {
        question: "كورا Cora",
        answer: "منصه تقدم مجموعة من الحلول للتحويل المباشر و الدفع واستقبال المدفوعات عبر الانترنت"
    },
    {
        question: "كيف أبدأ باستقبال المدفوعات؟",
        answer: "يمكنك البدء بالتسجيل بالموقع  الالكتروني في لحظات، ثم ابدأ بتجربة خدمات كورا"
    },
    {
        question: "طريقة استخدام روابط الدفع؟",
        answer: "إنشاء روابط دفع مخصصة بسهولة، ومشاركتها لاستقبال المدفوعات دون الحاجة إلى موقع إلكتروني"
    },
    {
        question: "هل يمكن إضافة أكثر من منتج في صفحة الدفع؟",
        answer: "نعم، يمكنك إنشاء صفحة دفع مخصصة وإضافة أكثر من منتج مع تفاصيل السعر والوصف لكل منتج"
    },
    {
        question: "هل يمكنني ربط حساباتي البنكية في كورا؟",
        answer: "يمكنك إضافة جميع حساباتك البنكية وربطها بالمنصة لإدارة ومتابعة التحويلات من مكان واحد"
    },
    {
        question: "هل يمكنني إجراء تحويلات بين الحسابات البنكية؟",
        answer: "نعم، تتيح لك كورا إجراء تحويلات سريعة ومباشرة بين أي حساب بنكي"
    },
    {
        question: "كيف يمكنني متابعة حالة المدفوعات؟",
        answer: "يمكنك متابعة جميع المدفوعات والتحويلات من خلال لوحة التحكم الرئيسية في كورا"
    },
    {
        question: "متى يتم تسوية المبلغ في روابط الدفع وما هو الحد الأدنى؟",
        answer: "يتم تسوية ومعالجة المدفوعات اسبوعياً، الحد الأدنى لتحصيل المبالغ هو 100 ريال"
    },
    {
        question: "ماذا أفعل في حال حدوث شكوى أو تعرضي لعملية نصب من خلال روابط الدفع؟",
        answer: "في حال وجود شكوى أو مشكلة، يمكنك التواصل معنا فورًا بحيث لاتتجاوز مدة تقديم الاعتراض 14 يوم من تاريخ الدفع عبر الدعم الفني لتقديم بلاغ. سنقوم بمراجعة تفاصيل العملية واتخاذ الإجراءات اللازمة لضمان حقوقك ومعالجة المشكلة"
    },
    {
        question: "هل كورا شركة معتمدة؟",
        answer: "شركة كورا لتقنية المعلومات مسجلة بشكل رسمي في المملكة العربية السعودية , تعمل كـ حلول دفع الكترونية، وهي واحدة من الشركات الريادية المحتضنة لدى البرنامج الوطني لتنمية تقنية المعلومات"
    }
];



function FAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div>

            <Helmet>
                <title>كورا - اسئلة مكرره </title>
            </Helmet>

            <h1 className='faq-title'>اسئلة مكرره</h1>
            <div className="faq-container">
                {faqData.map((item, index) => (
                    <div key={index} className="faq-item">

                        <div className="faq-question-container" onClick={() => toggleAnswer(index)}>

                            <span className="faq-icon">
                                {activeIndex === index ? '✕' : '＋'}
                            </span>

                            <h2 className="faq-question">
                                {item.question}
                            </h2>

                        </div>

                        {activeIndex === index && <p className="faq-answer">{item.answer}</p>}

                    </div>
                ))}
            </div>
        </div>

    );
}

export default FAQ;
