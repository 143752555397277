import { makeRequest } from "../core/data-access/http.service"; // Adjust the import path based on your project structure

/**
 * Login an end-user with the provided details
 * @param {string} phone_number - Phone number of the user
 * @param {string} password - User's password
 * @returns {Promise} Axios response
 */
export const loginEndUser = async (phone_number, password) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/authentication/sign-in-end-user`;

  try {
    const response = await makeRequest({
      url,
      method: "POST",
      data: {
        phone_number,
        password,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
