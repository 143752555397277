import React from "react";
import Footer from "./Footer";
import "./CoraPay.css";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

const LinkGenerate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const paymentLink = location.state?.paymentLink || "No link provided";

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`cora.sa/${paymentLink}`);
    alert("تم نسخ الرابط");
  };

  const handleRedirectToMain = () => {
    navigate("/Main");
  };

  const handleRedirectToCheckout = () => {
    // Navigate to the Checkout page with the paymentLink as a URL parameter
    navigate(`/${paymentLink}`);
  };
  return (
    <div className="CoraPay-main">
      <Helmet>
        <title>رابط الدفع</title>
      </Helmet>

      {/* Checkmark Icon and Text */}
      <div className="LinkGenerate-header">
        <div className="LinkGenerate-icon">
          <img src="/checkmark.png" alt="Checkmark" />
        </div>
        <h2 className="title-top">رابط الدفع الخاص بك</h2>
      </div>

      {/* Payment Link Section */}
      <div className="LinkGenerate-content">
        <div className="LinkGenerate-link-container">
          <p onClick={handleRedirectToCheckout} className="LinkGenerate-link">
            {`cora.sa/${paymentLink}`}
          </p>
          <button className="CoraPay-copy-btn" onClick={handleCopyLink}>
            <img src="/copy.png" alt="Copy Link" className="CoraPay-icon" />
          </button>
        </div>

        <p className="LinkGenerate-info">
          لمتابعة والتحكم في المدفوعات من الصفحة الرئيسية
        </p>
      </div>

      {/* Buttons */}
      <div className="LinkGenerate-buttons">
        <button
          className="LinkGenerate-main-btn"
          onClick={handleRedirectToMain}
        >
          الصفحة الرئيسية
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default LinkGenerate;
