import { makeRequest } from "../core/data-access/http.service";

/**
 * Finalize the user payment
 * @param {string} payTransactionExternalKey - The payment transaction external key
 * @param {string} lockId - The lock ID
 * @returns {Promise} Axios response
 */
export const finalizeUserPayment = async (payTransactionExternalKey, lockId) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/endUser/payment-link-resolver/finalize-user-payment`;

  try {
    const response = await makeRequest({
      url,
      method: "POST",
      data: {
        pay_transaction_external_key: payTransactionExternalKey,
        lock_id: lockId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
