import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './CoraPay.css';


const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="CoraPay-footer">

            <button
                className={`CoraPay-footer-btn ${location.pathname === '/Main' ? 'active' : ''}`}
                onClick={() => navigate('/Main')}
            >
                <i className="fas fa-home"></i>
                <span>الرئيسية</span>
            </button>
            <button
                className={`CoraPay-footer-btn ${location.pathname === '/Activity' ? 'active' : ''}`}
                onClick={() => navigate('/Activity')}
            >
                <i className="fas fa-list"></i>
                <span>المدفوعات</span>
            </button>
            <button
                className={`CoraPay-footer-btn ${location.pathname === '/Profile' ? 'active' : ''}`}
                onClick={() => navigate('/Profile')}
            >
                <i className="fas fa-user"></i>
                <span>الملف الشخصي</span>
            </button>
        </div>
    );
};


export default Footer;
