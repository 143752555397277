import React, { useState } from "react";
import Footer from "./Footer";
import "./CoraPay.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { generatePaymentLink } from "../../services/generate-payment-link.service";

const Links = () => {
  const navigate = useNavigate();

  const [linkTitle, setLinkTitle] = useState("");
  const [products, setProducts] = useState([
    { id: 1, name: "", description: "", price: "", image: "" },
  ]);
  const [loading, setLoading] = useState(false);

  const handleProductChange = (id, field, value) => {
    const updatedProducts = products.map((product) =>
      product.id === id ? { ...product, [field]: value } : product
    );
    setProducts(updatedProducts);
  };

  const handleAddProduct = () => {
    const newProduct = {
      id: Date.now(),
      name: "",
      description: "",
      price: "",
      image: "",
    };
    setProducts([...products, newProduct]);
  };

  const handleRemoveProduct = (id) => {
    const updatedProducts = products.filter((product) => product.id !== id);
    setProducts(updatedProducts);
  };

  const handleGenerateLink = async () => {
    if (!linkTitle.trim()) {
      alert("Please enter a link title.");
      return;
    }

    if (products.some((product) => !product.name || !product.price)) {
      alert("Please complete all product details.");
      return;
    }

    const formData = new FormData();
    formData.append("link_title", linkTitle);
    products.forEach((product, index) => {
      formData.append(`products[${index}].name`, product.name);
      formData.append(`products[${index}].description`, product.description);
      formData.append(`products[${index}].price`, product.price);
      formData.append(`products[${index}].product_image`, product.image); // Ensure product.image is a File or Blob
    });

    try {
      setLoading(true);
      const response = await generatePaymentLink(formData);
      const paymentLink = response; // Assuming the link is returned here

      if (paymentLink) {
        navigate("/Your-link", { state: { paymentLink } }); // Pass link as state
      } else {
        throw new Error("No payment link returned");
      }
    } catch (error) {
      console.error("Error generating link:", error.response || error.message);
      alert("Failed to generate the link. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="CoraPay-main">
      <Helmet>
        <title>انشاء رابط دفع</title>
      </Helmet>

      <div className="Payment-link-header">
        <label>عنوان رابط الدفع</label>
        <input
          type="text"
          className="link-input"
          value={linkTitle}
          onChange={(e) => setLinkTitle(e.target.value)}
        />
      </div>

      <div className="Link-add-container">
        <button className="Link-add-btn" onClick={handleAddProduct}>
          +
        </button>
      </div>

      <div className="Payment-product-list">
        {products.map((product) => (
          <div key={product.id} className="Payment-product-item">
            <div className="Payment-product-fields">
              <div className="Payment-image-container">
                {product.image && (
                  <img
                    src={URL.createObjectURL(product.image)}
                    alt="Product"
                    className="Payment-product-image"
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleProductChange(product.id, "image", e.target.files[0])
                  }
                  className="Payment-image-input"
                />
              </div>
              <label className="form-label">اسم المنتج</label>
              <input
                type="text"
                value={product.name}
                onChange={(e) =>
                  handleProductChange(product.id, "name", e.target.value)
                }
                className="link-input"
                required
              />
              <textarea
                placeholder="وصف مختصر"
                value={product.description}
                onChange={(e) =>
                  handleProductChange(product.id, "description", e.target.value)
                }
                className="link-input"
                maxLength={100}
                rows="3"
                required
              />
              <label className="form-label">السعر</label>
              <input
                type="text"
                placeholder="1"
                value={product.price}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                  handleProductChange(product.id, "price", sanitizedValue);
                }}
                className="link-input"
                inputMode="numeric"
                pattern="[0-9]*"
                min="1"
                required
              />
            </div>
            <button
              className="Payment-remove-btn"
              onClick={() => handleRemoveProduct(product.id)}
            >
              حذف
            </button>
          </div>
        ))}
      </div>

      <div className="Payment-button-container">
        <button
          className="Payment-generate-btn"
          onClick={handleGenerateLink}
          disabled={loading}
        >
          {loading ? "جاري الانشاء..." : "انشاء رابط دفع"}
        </button>
        <p className="Payment-terms-text">
          بالضغط على زر انشاء رابط دفع انت توافق على الشروط والاحكام
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Links;
