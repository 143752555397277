import React from "react";
import { useFormik } from "formik";
import { loginValidationSchema } from "../validation/loginValidation"; // Use the same Yup validation schema
import { loginEndUser } from "../../services/login.service";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const LoginPopup = ({ showPopup, closePopup }) => {
  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },
    validationSchema: loginValidationSchema(), // Use the same Yup validation schema
    onSubmit: async () => {
      await handleSubmit();
    },
  });

  const handleSubmit = async () => {
    const phoneNumber = formik.values.phoneNumber;
    const password = formik.values.password;

    try {
      const response = await loginEndUser(phoneNumber, password);
      Cookies.set("authToken", response.auth_token, {
        path: "/",
        domain: ".cora.sa",
        secure: true,
        sameSite: "Strict",
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          fullname: response.fullname,
          phoneNumber: response.phone_number,
        })
      );
      toast.success("تم تسجيل الدخول بنجاح");
      closePopup(); // Close the popup after successful login
    } catch (error) {
      toast.warning(error.message);
    }
  };

  return (
    <>
      {showPopup && (
        <div
          dir="rtl"
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        >
          <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
            {/* Change button alignment to the left */}
            <button
              className="absolute top-2 left-2 text-gray-500 hover:text-gray-700"
              onClick={closePopup}
            >
              X
            </button>
            <div className="flex flex-col items-start">
              {" "}
              {/* Align all items to the right */}
              <h2 className="text-xl font-semibold font-['Rubik'] mb-4">
                تسجيل الدخول
              </h2>
              <form onSubmit={formik.handleSubmit} className="w-full">
                <div className="mb-4">
                  <label className="block font-['Rubik'] text-sm font-medium text-gray-700">
                    رقم الجوال
                  </label>
                  <input
                    type="tel"
                    dir="rtl"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full p-3 border font-['Rubik'] border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="أدخل رقم الجوال"
                    required
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <div className="text-red-600 font-['Rubik'] text-sm">
                      {formik.errors.phoneNumber}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-['Rubik'] font-medium text-gray-700">
                    كلمة المرور
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full font-['Rubik'] p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="أدخل كلمة المرور"
                    required
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-red-600 font-['Rubik'] text-sm">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#5A38FF] font-['Rubik'] text-white py-3 rounded-md hover:bg-blue-700 focus:outline-none"
                >
                  تسجيل الدخول
                </button>
              </form>
              {/* Additional Links */}
              <div className="w-full mt-4 text-center">
                <div className="mb-2">
                  <a
                    href="/Forget-Password"
                    className="text-sm font-['Rubik'] text-blue-600 hover:underline"
                  >
                    نسيت كلمة المرور؟
                  </a>
                </div>
                <div>
                  <a
                    href="/registration"
                    className="text-sm text-blue-600 hover:underline font-['Rubik']"
                  >
                    ليس لديك حساب؟ سجل الآن
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPopup;
