import React from "react";
import "./CoraPay.css";
import { Helmet } from "react-helmet";
import MoyasarPayment from "./MoyasarPayment";
import { useLocation } from "react-router-dom";

const Summary = () => {
  const location = useLocation();
  const { selectedProducts, linkUrl, locId } = location.state || {};
  const description = `cora.sa/${linkUrl}$${locId}$${selectedProducts
    .map((product) => product.product_id)
    .join("$")}`;
  const calculateTotal = () => {
    return selectedProducts.reduce(
      (total, product) => total + product.price,
      0
    );
  };
  const totalAmount = calculateTotal() * 100;
  return (
    <div className="CoraPay-Summary">
      <Helmet>
        <title>ملخص عملية الدفع</title>
      </Helmet>
      <h2 className="Summary-title">ملخص العملية</h2>
      <table className="Summary-table">
        <thead>
          <tr>
            <th>السعر</th>
            <th>اسم المنتج</th>
          </tr>
        </thead>
        <tbody>
          {selectedProducts.map((product) => (
            <tr key={product.product_id}>
              <td>{product.price} ريال</td>
              <td>{product.name}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <strong>{calculateTotal()} ريال</strong>
            </td>
            <td>
              <strong>الإجمالي</strong>
            </td>
          </tr>
        </tfoot>
      </table>
      <MoyasarPayment
        amount={totalAmount}
        description={description}
        currency={"SAR"}
        callbackUrl={`${process.env.REACT_APP_BASE_URL}/payment-status?locId=${locId}`}
        publishableKey={process.env.REACT_APP_MOYASAR_PUBLISH_ABLE_KEY}
        onCompleted={`${process.env.REACT_APP_BACKEND_URL}/api/endUser/payment-link-resolver/initialize-user-payment`}
      />
    </div>
  );
};

export default Summary;
