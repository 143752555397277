import React from "react";
import "./CoraPay.css";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { loginValidationSchema } from "../validation/loginValidation";
import { loginEndUser } from "../../services/login.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Login = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },
    validationSchema: loginValidationSchema(), // Attach your Yup schema
    onSubmit: async () => {
      await handleSubmit();
    },
  });
  const handleSubmit = async () => {
    const phoneNumber = formik.values.phoneNumber; // Ensure it's in the correct format (e.g., starts with "966")
    const password = formik.values.password;
    try {
      const response = await loginEndUser(phoneNumber, password);
      // Store the auth token in a cookie with domain-wide access
      Cookies.set("authToken", response.auth_token, {
        path: "/",
        domain: ".cora.sa",
        secure: true,
        sameSite: "Strict",
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          fullname: response.fullname,
          phoneNumber: response.phone_number,
        })
      );
      toast.success("تم تسجيل الدخول بنجاح");
      navigate("/main");
    } catch (error) {
      if (error.response.data === "WRONG_CREDENTIALS") {
        toast.warning("رقم الجوال أو كلمة المرور غير صحيحة");
      } else {
        toast.warning(error.message);
      }

      return error;
    }
  };
  return (
    <div className="CoraPay">
      <Helmet>
        <title>تسجيل الدخول</title>
      </Helmet>

      <div className="CoraPayForm">
        <h2>تسجيل دخول</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="CoraPay-form-group">
            <label htmlFor="phone">رقم الجوال</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className="text-red-600 text-sm">
                {formik.errors.phoneNumber}
              </div>
            )}
          </div>
          <div className="CoraPay-form-group">
            <label htmlFor="passkey">كلمة المرور</label>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-600 text-sm">
                {formik.errors.password}
              </div>
            )}
          </div>
          <button type="sumbit" className="CoraPay-MainButton">
            دخول
          </button>
        </form>
        <button
          className="CoraPay-SecButton"
          onClick={() => navigate("/registration")}
        >
          حساب جديد
        </button>
        <button
          className="CoraPay-SecButton"
          onClick={() => navigate("/Forget-Password")}
        >
          نسيت كلمة المرور؟
        </button>
      </div>
    </div>
  );
};

export default Login;
