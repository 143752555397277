import * as yup from "yup";

export const paymentMethodValidation = () => {
  return yup.object().shape({
    full_name: yup
      .string()
      .min(3, "الاسم الكامل يجب أن يكون على الأقل 3 أحرف")
      .required("الاسم الكامل مطلوب"),
    saudi_id_number: yup
      .string()
      .matches(/^[0-9]{10}$/, "رقم الهوية يجب أن يتكون من 10 أرقام فقط")
      .required("رقم الهوية مطلوب"),
    bank_name: yup.string().required("اسم البنك مطلوب"),
    iban: yup
      .string()
      .matches(
        /^SA\d{22}$/,
        "يجب أن يبدأ رقم IBAN بـ SA ويتكون من 24 رمزًا"
      )
      .required("رقم IBAN مطلوب"),
  });
};

