import Cookies from "js-cookie";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = Cookies.get("authToken"); // Check for token
  const location = useLocation(); // Get current location

  return token ? (
    children
  ) : (
    <Navigate to="/Login" replace state={{ from: location }} />
  );
};

export default ProtectedRoute;
