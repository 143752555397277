import { makeRequest } from "../core/data-access/http.service";

/**
 * Resolve the payment link for the selected products
 * @param {string} url_link - The payment link URL
 * @param {Array} products_ids - The array of selected product IDs
 * @returns {Promise} Axios response
 */
export const resolvePaymentLinkBeginCheckout = async (
  url_link,
  products_ids
) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/endUser/payment-link-resolver/begin-checkout`;

  try {
    const response = await makeRequest({
      url,
      method: "POST",
      data: {
        url_link,
        products_ids,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
