import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { fetchResolverPaymentLinks } from "../../services/fetch-resolver-payment-links.service";
import { resolvePaymentLinkBeginCheckout } from "../../services/payment-link-resolver-begin-checkout.service";
import LoginPopup from "./login-popup-component";
import "./CoraPay.css";
import NotFoundComponent from "../not-found-component";
import Cookies from "js-cookie";

const Checkout = () => {
  const [productsData, setProductsData] = useState([
    { link_title: "", products: [] },
  ]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [expandedProductId, setExpandedProductId] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const { linkUrl } = useParams();
  const navigate = useNavigate();

  const handleToggleDescription = (id) => {
    setExpandedProductId((prevId) => (prevId === id ? null : id));
  };

  const handleSelectionChange = (productId) => {
    setSelectedProductIds((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const calculateTotal = () => {
    if (!productsData.products) return 0;
    return productsData.products
      .filter((product) => selectedProductIds.includes(product.product_id))
      .reduce((total, product) => total + product.price, 0);
  };

  const handleCompletePayment = async () => {
    const token = Cookies.get("authToken");

    if (!token) {
      setShowLoginPopup(true);
      return;
    }

    const selectedProducts = productsData.products.filter((product) =>
      selectedProductIds.includes(product.product_id)
    );

    try {
      const response = await resolvePaymentLinkBeginCheckout(
        `cora.sa/${linkUrl}`,
        selectedProducts.map((product) => product.product_id)
      );
      const locId = response;
      navigate("/summary", { state: { selectedProducts, linkUrl, locId } });
    } catch (error) {
      console.error("Error during payment process:", error);
    }
  };

  const fetchPaymentLists = useCallback(async () => {
    try {
      const response = await fetchResolverPaymentLinks(linkUrl);
      setProductsData(response);
    } catch (error) {
      setIsWrong(true);
      console.error();
      return error;
    }
  }, [linkUrl]);

  const handleLoginClose = () => {
    setShowLoginPopup(false);
  };

  useEffect(() => {
    fetchPaymentLists();
  }, [fetchPaymentLists]);

  return (
    <div className="CoraPay-checkout">
      <Helmet>
        <title>Checkout</title>
      </Helmet>

      {isWrong ? (
        <NotFoundComponent /> // Display Nof Found
      ) : (
        <>
          <h2 className="Checkout-title">{productsData.link_title}</h2>
          <div className="Checkout-products">
            {productsData.products?.map((product) => (
              <div key={product.product_id} className="Checkout-product">
                <img
                  src={product.product_image_url}
                  alt={product.name}
                  className="Checkout-product-image"
                />
                <div className="Checkout-product-details">
                  <div>
                    <h4 className="Checkout-product-name">{product.name}</h4>
                    <p
                      className="Checkout-product-description"
                      onClick={() =>
                        handleToggleDescription(product.product_id)
                      }
                    >
                      {expandedProductId === product.product_id
                        ? product.description
                        : `${product.description.slice(0, 20)}...`}
                    </p>
                    <p className="Checkout-product-price">
                      {product.price} SAR
                    </p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  checked={selectedProductIds.includes(product.product_id)}
                  onChange={() => handleSelectionChange(product.product_id)}
                  className="Checkout-product-checkbox"
                />
              </div>
            ))}
          </div>

          <div className="Checkout-footer">
            <p className="Checkout-total">الإجمالي: {calculateTotal()} ريال</p>
            <button
              className="Payment-generate-btn"
              onClick={handleCompletePayment}
              disabled={selectedProductIds.length === 0}
            >
              أكمل الدفع
            </button>
          </div>
        </>
      )}

      <LoginPopup showPopup={showLoginPopup} closePopup={handleLoginClose} />
    </div>
  );
};

export default Checkout;
