import React, { useEffect } from "react";

const loadMoyasarScripts = () => {
  const script1 = document.createElement("script");
  script1.src =
    "https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=fetch";
  script1.async = true;
  document.body.appendChild(script1);

  const script2 = document.createElement("script");
  script2.src = "https://cdn.moyasar.com/mpf/1.14.0/moyasar.js";
  script2.async = true;
  document.body.appendChild(script2);

  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = "https://cdn.moyasar.com/mpf/1.14.0/moyasar.css";
  document.head.appendChild(link);

  return () => {
    document.body.removeChild(script1);
    document.body.removeChild(script2);
    document.head.removeChild(link);
  };
};

const MoyasarPayment = ({
  amount,
  currency,
  description,
  callbackUrl,
  publishableKey,
  onCompleted,
}) => {
  useEffect(() => {
    const cleanup = loadMoyasarScripts();

    const initializeMoyasar = () => {
      if (window.Moyasar) {
        window.Moyasar.init({
          element: ".mysr-form",
          amount,
          currency,
          description,
          publishable_api_key: publishableKey,
          callback_url: callbackUrl,
          methods: ["creditcard", "applepay"], // Add "applepay" if needed
          apple_pay: {
            country: "SA",
            label: "Cora",
            validate_merchant_url:
              "https://api.moyasar.com/v1/applepay/initiate",
          },
          supported_networks: ["mada"],
          on_completed: onCompleted,
        });
      }
    };

    const interval = setInterval(() => {
      if (window.Moyasar) {
        initializeMoyasar();
        clearInterval(interval);
      }
    }, 100);

    return () => {
      cleanup();
      clearInterval(interval);
    };
  }, [amount, currency, description, callbackUrl, publishableKey, onCompleted]);

  return (
    <div className="payment-container">
      <div className="mysr-form"></div>
    </div>
  );
};

export default MoyasarPayment;
