import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";

import MainSection from "./components/LandingPage/Main";
import Features from "./components/LandingPage/Featuers";
import FAQ from "./components/LandingPage/Faq";
import PrivacyPolicy from "./components/LandingPage/PrivacyPolicy";
import Contact from "./components/LandingPage/Contact";
import Tryout from "./components/LandingPage/Tryout";
import EmailSub from "./components/LandingPage/EmailSub";
import Registration from "./components/CoraPay.jsx/Registration";
import OtpLayout from "./components/CoraPay.jsx/OtpLayout";
import MainLayout from "./components/CoraPay.jsx/MainLayout";
import Activity from "./components/CoraPay.jsx/Activity";
import Profile from "./components/CoraPay.jsx/Profile";
import Links from "./components/CoraPay.jsx/Links";
import LinkGenerate from "./components/CoraPay.jsx/LinkGenerate";
import Checkout from "./components/CoraPay.jsx/Checkout";
import Summary from "./components/CoraPay.jsx/Summary";
import Success from "./components/CoraPay.jsx/Success";
import Failed from "./components/CoraPay.jsx/Failed";
import ForgetPass from "./components/CoraPay.jsx/ForgetPass";
import Login from "./components/CoraPay.jsx/Login";
import PaymentStatusHandler from "./components/CoraPay.jsx/payment-status-handler";
import ProtectedRoute from "./routes/protect-routes";

const HomePage = () => {
  return (
    <>
      <MainSection />
      <Features />
      <Tryout />
      <EmailSub />
    </>
  );
};

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routesWithFooter = [
    "/",
    "/FAQ",
    "/PrivacyPolicy",
    "/Contact",
    "/PaymentDetail",
  ];

  React.useEffect(() => {
    if (window.location.pathname === "/PaymentDetail") {
      navigate("/PaymentDetail");
    }
  }, [navigate]);

  return (
    <div className="App">
      <Helmet>
        <title>كورا - الرئيسية </title>
      </Helmet>

      <div className="header">
        <a href="https://Cora.sa" rel="noopener noreferrer">
          <img src="/coralogo.png" alt="Cora" className="logo" />
        </a>
      </div>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Registration" element={<Registration />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/otp-verfiy" element={<OtpLayout />} />
        <Route
          path="/Main"
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Activity"
          element={
            <ProtectedRoute>
              <Activity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="/Create-link" element={<Links />} />
        <Route path="/Your-link" element={<LinkGenerate />} />
        <Route path="/:linkUrl" element={<Checkout />} />
        <Route path="/Summary" element={<Summary />} />
        <Route path="/Success" element={<Success />} />
        <Route path="/Failed" element={<Failed />} />
        <Route path="/Forget-Password" element={<ForgetPass />} />
        <Route path="/payment-status" element={<PaymentStatusHandler />} />
      </Routes>

      {routesWithFooter.includes(location.pathname) && (
        <div className="footer">
          <div className="footer-content">
            <p className="copyright">Cora 2023 ©</p>
            <div>
              <Link to="/PrivacyPolicy" className="footer-link">
                سياسة الخصوصية
              </Link>
              <Link to="/FAQ" className="footer-link">
                الأسئلة المتكررة
              </Link>
              <Link to="/Contact" className="footer-link">
                تواصل معنا
              </Link>
              <Link to="https://x.com/coraPayment" className="footer-link">
                تابعنا
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
