import * as yup from "yup";

export const loginValidationSchema = () => {
  return yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(/^[0-9]{10}$/, "رقم الجوال يجب أن يتكون من 10 أرقام فقط")
      .required("رقم الجوال مطلوب"),
    password: yup
      .string()
      .min(8, "كلمة المرور يجب أن تكون على الأقل 8 أحرف.")
      .max(22, "يجب أن تتكون كلمة المرور من 22 حرفًا على الأكثر")
      .required("كلمة المرور مطلوبة."),
  });
};
