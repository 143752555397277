import React, { useState } from 'react';
import './CoraPay.css';
import { Helmet } from 'react-helmet';


const OtpLayout = () => {
    const [otp, setOtp] = useState('');

    return (
        <div className="CoraPay">

            <Helmet>
                <title>رمز التحقق</title>
            </Helmet>

            <label className='otp-header'>ادخل رمز التحقق</label>
            <p>ادخل رمز التحقق المرسل لرقم الجوال المسجل</p>

            <div className="CoraPay-form-group">
                <label htmlFor="OTP">رمز التحقق</label>
                <input
                    type="otp"
                    id="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                />
            </div>
        </div>

    );
};


export default OtpLayout;
