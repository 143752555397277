import { makeRequest } from "../core/data-access/http.service"; // Adjust the path as needed

/**
 * Add bank details for the end user
 * @param {Object} paymentMethod - The payment method details to be added
 * @param {string} paymentMethod.full_name - Full name of the user
 * @param {string} paymentMethod.saudi_id_number - Saudi ID number
 * @param {string} paymentMethod.bank_name - Bank name
 * @param {string} paymentMethod.iban - IBAN number
 * @returns {Promise} Axios response
 */
export const addPyamentMethod = async (paymentMethod) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/endUser/payments-methods/add-payment-method`;

  try {
    const response = await makeRequest({
      url,
      method: "POST",
      data: paymentMethod,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
