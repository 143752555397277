import { makeRequest } from "../core/data-access/http.service"; // Adjust the import path based on your project structure

/**
 * Register an end-user with the provided details
 * @param {string} full_name - Full name of the user
 * @param {string} phone_number - Phone number of the user
 * @param {string} otpCode - OTP code sent to the user
 * @param {string} password - User's password
 * @returns {Promise} Axios response
 */
export const registerEndUser = async (
  full_name,
  phone_number,
  otpCode,
  password
) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/authentication/resiger-end-user`;

  try {
    const response = await makeRequest({
      url,
      method: "POST",
      data: {
        full_name,
        phone_number,
        otpCode,
        password,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
