import React, { useEffect } from "react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { finalizeUserPayment } from "../../services/finalize-user-pyament.service";

const PaymentStatusHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const locId = searchParams.get("locId");
  // const status = searchParams.get("status");
  const message = searchParams.get("message");
  // const amount = searchParams.get("amount");

  const finalizePayment = useCallback(async () => {
    try {
      // Replace with actual lock ID if applicable
      await finalizeUserPayment(id, locId);
      if (message === "APPROVED") {
        navigate("/Success");
      } else {
        navigate("/Failed");
      }
    } catch (error) {
      return error;
    }
  }, [id, locId, message, navigate]);
  useEffect(() => {
    finalizePayment();
  }, [finalizePayment]);

  return <div>Processing payment...</div>;
};

export default PaymentStatusHandler;
