import { useNavigate } from "react-router-dom";
function NotFoundComponent() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-9xl font-extrabold text-gray-800">404</h1>
      <p className="text-2xl font-medium text-gray-600 mt-4">Page Not Found</p>
      <button
        onClick={() => navigate("/")}
        className="mt-8 px-6 py-3 text-white bg-blue-600 hover:bg-blue-700 rounded-lg text-lg shadow-md transition-all"
      >
        Go to Home
      </button>
    </div>
  );
}

export default NotFoundComponent;
