import React, { useState } from "react";
import Footer from "./Footer";
import "./CoraPay.css";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { fetchPurchasesLinks } from "../../services/fetch-links-purchases.service";

const Activity = () => {
  const [purchases, setPurchases] = useState([]);
  const fetchPurachasesLinkdData = async () => {
    try {
      const response = await fetchPurchasesLinks();
      setPurchases(response.purchases);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    fetchPurachasesLinkdData();
  }, []);
  return (
    <div className="CoraPay-main">
      <Helmet>
        <title>العمليات</title>
      </Helmet>
      <label className="CoraPay-label">العمليات</label>
      <ul className="CoraPay-list">
        {purchases.map((item) => (
          <li key={item.purchase_id} className="CoraPay-list-item">
            <div className="CoraPay-item-left">
              <p
                className={`CoraPay-item-status ${
                  item.purchase_status === "PAID" ||
                  item.purchase_status === "PURCHASED"
                    ? "active"
                    : item.purchase_status === "SUSPENDED"
                    ? "pending"
                    : item.purchase_status === "REJECTED"
                    ? "canceled"
                    : ""
                }`}
              >
                {item.purchase_status}
              </p>
            </div>

            <div className="CoraPay-item-right">
              <div className="CoraPay-item-details">
                <h4>{item.product_title}</h4>
                <p>
                  {item.amount} SAR
                  <br />
                  تم الدفع بواسطة: {item.purchaser_phone}
                  <br />
                  {new Date(item.purchase_datetime).toLocaleDateString()} -{" "}
                  {new Date(item.purchase_datetime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <Footer />
    </div>
  );
};

export default Activity;
