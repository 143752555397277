import React from 'react';
import { useNavigate } from 'react-router-dom';
import './main.css';
import { Helmet } from 'react-helmet';


function MainSection() {
    const navigate = useNavigate();

    const handNavigate = () => {
        navigate('/contact');
    };

    const startNavigate = () => {
        window.location.href = '/Registration';
    };


    return (
        <div className="main-section-container">
            <Helmet>
                <title>كورا - حلول المدفوعات </title>
            </Helmet>
            <div className="main-section">

                <label className='betaMain'>!ابدأ الآن ●</label>

                <p className='dis-main'>حلول الدفع واستقبال المدفوعات</p>
                <p className='small-desc'>حلول المدفوعات وصفحات الخروج المخصصة، ادفع واستقبل مدفوعاتك من مكان واحد</p>
                <div className="button-container">
                    <button className="cta-button" onClick={startNavigate}>
                        التسجيل
                        <span className="arrow"></span></button>
                    <button className="con-button" onClick={handNavigate}>
                        تواصل معنا
                    </button>
                </div>
            </div>
        </div >
    );
}

export default MainSection;