import { makeRequest } from "../core/data-access/http.service"; // Adjust the import path based on your project structure

/**
 * Fetch list purchases links
 * @returns {Promise} Axios response with the list of previous links
 */
export const fetchPurchasesLinks = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/endUser/payment-link-generator/list-links-purchases`;

  try {
    const response = await makeRequest({
      url,
      method: "GET",
    });
    return response;
  } catch (error) {
    console.error("Error fetching payment links:", error);
    throw error;
  }
};
