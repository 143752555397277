import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CoraPay.css";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { registerValidationSchema } from "../validation/registervalidation";
import { sendOtp } from "../../services/send-otp.service";
import { registerEndUser } from "../../services/reqister.service";
import { toast } from "react-toastify";
const Registration = () => {
  const [showOtpField, setShowOtpField] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      otp: "",
      password: "",
    },
    validationSchema: registerValidationSchema(showOtpField),
    onSubmit: async () => {
      await handleSubmit();
    },
  });
  const handleSendOtp = async () => {
    try {
      if (!formik.values.phoneNumber) {
        alert("Please enter a valid phone number.");
        return;
      }

      await sendOtp(formik.values.phoneNumber, "USER_REGISTRATION");
      toast.success("Otp sent successfully");
      setShowOtpField(true);
    } catch (error) {
      if (error.response.data === "EXISTING_PHONE_NUMBER") {
        toast.warn("الرقم مسجل بالفعل");
      } else {
        toast.warn(error.message);
      }
    }
  };
  const handleSubmit = async () => {
    const fullName = formik.values.fullName;
    const phoneNumber = formik.values.phoneNumber;
    const otpCode = formik.values.otp;
    const password = formik.values.password;
    try {
      await registerEndUser(fullName, phoneNumber, otpCode, password);
      toast.success("تم التسجيل ببنجاح");
      navigate("/login");
    } catch (error) {
      toast.warning(error.message);
      return error;
    }
  };
  return (
    <div className="CoraPay">
      <Helmet>
        <title>التسجيل</title>
      </Helmet>

      <div className="CoraPayForm">
        <h2>حساب جديد</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="CoraPay-form-group">
            <label htmlFor="name">الاسم</label>
            <input
              type="text"
              id="name"
              name="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.fullName && formik.errors.fullName && (
              <div className="text-sm text-red-600">
                {formik.errors.fullName}
              </div>
            )}
          </div>
          <div className="CoraPay-form-group">
            <label htmlFor="phone">رقم الجوال</label>
            <input
              type="tel"
              id="phone"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="05"
              required
            />
            {formik.touched.phoneNumber && !formik.errors.phoneNumber && (
              <button
                type="button"
                className="CoraPay-SecButton"
                onClick={handleSendOtp}
              >
                إرسال رمز التحقق
              </button>
            )}
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className="text-sm text-red-600">
                {formik.errors.phoneNumber}
              </div>
            )}
          </div>
          {showOtpField && (
            <div className="CoraPay-form-group">
              <label htmlFor="otp">رمز التحقق</label>
              <input
                type="text"
                id="otp"
                name="otp"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.otp && formik.errors.otp && (
                <div className="text-sm text-red-600">{formik.errors.otp}</div>
              )}
            </div>
          )}
          <div className="CoraPay-form-group">
            <label htmlFor="password">انشاء كلمة مرور</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-sm text-red-600">
                {formik.errors.password}
              </div>
            )}
          </div>
          <button className="CoraPay-MainButton" type="submit">
            تسجيل
          </button>
          <button
            className="CoraPay-SecButton"
            onClick={() => navigate("/login")}
          >
            تسجيل دخول
          </button>
        </form>
      </div>
    </div>
  );
};

export default Registration;
