import React from 'react';
import './Featuers.css';

function Features() {
    return (
        <div className="Featuers-section">

            <label className='betaMain'>  مزايا  ●</label>
            <h1 className="main-title">خيارات مرنة تناسب احتياجاتك ومرنة لمدفوعاتك</h1>

            <div className="Featuers-container">

                <div className="Featuers-card">
                    <img src="wallet.png" alt="Feature" className="feature-image" />
                    <h2>تحويلات سريعة ومباشرة</h2>
                    <p>تحويلات مباشرة بين حساباتك البنكية أو لحسابات أخرى سهل وآمن</p>
                </div>

                <div className="Featuers-card">
                    <img src="checkout.png" alt="Feature" className="feature-image" />
                    <h2>صفحة خروج مخصصة</h2>
                    <p>أنشئ صفحة دفع مخصصة، وأضف منتجات متعددة لعرض تفاصيلها وإتمام عمليات الشراء بسهولة</p>
                </div>

                <div className="Featuers-card">
                    <img src="shcd.png" alt="Feature" className="feature-image" />
                    <h2>حساباتك البنكية في مكان واحد</h2>
                    <p>اربط حساباتك البنكية في مكان واحد لتسهيل وإدارة عمليات التحويل</p>
                </div>

                <div className="Featuers-card">
                    <img src="2pay.png" alt="Feature" className="feature-image" />
                    <h2>روابط الدفع</h2>
                    <p>استخدم روابط الدفع للبيع عبر الإنترنت دون الحاجة إلى موقع إلكتروني. أنشئ رابط دفع بخطوات بسيطة وسهل المشاركة</p>
                </div>

            </div>
        </div>
    );
};

export default Features;
