import React from 'react';
import './CoraPay.css';
import { Helmet } from 'react-helmet';

const Success = () => {
    return (
        <div className="CoraPay-main">

            <Helmet>
                <title>عملية ناجحة</title>
            </Helmet>

            <div className="Note-container">
                <img
                    src="/checkmark.png"
                    alt="Note"
                    className="Note-image"
                />
                <h2 className="Note-title">تمت العملية بنجاح</h2>
                <p className="Note-message">
                    شكراً لك. لقد تمت العملية بنجاح, يمكنك الآن العودة إلى الصفحة الرئيسية، في حال لم يصلك المنتج تواصل معنا
                </p>
                <button
                    className="LinkGenerate-main-btn"
                    onClick={() => (window.location.href = '/')}
                >
                    العودة إلى الصفحة الرئيسية
                </button>
            </div>
        </div>
    );
};

export default Success;
