import { makeRequest } from "../core/data-access/http.service"; // Adjust the import path based on your project structure

/**
 * Deactivate a payment link
 * @param {string} paymentId - The ID of the payment to deactivate
 * @returns {Promise} Axios response
 */
export const deactivatePaymentLink = async (paymentId) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/endUser/payment-link-generator/deactivate-link`;

  try {
    const response = await makeRequest({
      url,
      method: "POST",
      data: {
        payment_id: paymentId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
