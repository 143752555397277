import React from "react";
import "./CoraPay.css";
import { Helmet } from "react-helmet";

const Failed = () => {
  return (
    <div className="CoraPay-main">
      <Helmet>
        <title>فشل في تنفيذ العملية</title>
      </Helmet>

      <div className="Note-container">
        <img src="/Failed.png" alt="Note" className="Note-image" />
        <h2 className="Failed-title">فشل في تنفيذ العملية</h2>
        <p className="Note-message">
          حدث خطأ أثناء تنفيذ العملية. يرجى المحاولة مرة أخرى
        </p>
        <button
          className="LinkGenerate-main-btn"
          onClick={() => (window.location.href = "/")}
        >
          العودة إلى رابط الدفع
        </button>
      </div>
    </div>
  );
};

export default Failed;
