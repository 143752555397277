import { makeRequest } from "../core/data-access/http.service"; // Adjust the import path based on your project structure

/**
 * Generate a new payment link
 * @param {string} linkTitle - Title of the payment link
 * @param {Array} products - Array of product objects with name, description, price, and image
 * @returns {Promise} Axios response
 */
export const generatePaymentLink = async (formData) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/endUser/payment-link-generator/generate-new-link`;
  try {
    const response = await makeRequest({
      url,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/plain",
      },
    });
    return response;
  } catch (error) {
    console.error("Error generating payment link:", error);
    throw error;
  }
};
