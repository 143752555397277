import axiosObject from "../configs/axios.configs";

/**
 * Make an HTTP request using axios
 * @param {Object} req - Request object
 * @param {string} req.url - Request URL
 * @param {string} req.method - HTTP method (e.g., "GET", "POST")
 * @param {Object} [req.data] - Request body (for POST, PUT, etc.)
 * @param {Object} [req.params] - URL parameters
 * @param {Object} [req.headers] - Custom headers
 * @param {Function} [req.paramsSerializer] - Custom parameter serializer
 * @returns {Promise} Axios response
 */
export const makeRequest = async ({
  url,
  method,
  data,
  params,
  headers,
  paramsSerializer,
}) => {
  try {
    const response = await axiosObject({
      url,
      method,
      data,
      params,
      headers,
      paramsSerializer,
    });
    return response.data;
  } catch (error) {
    console.error("HTTP Request Error: ", error);
    throw error;
  }
};
