import * as yup from "yup";

export const registerValidationSchema = (context) => {
  return yup.object().shape({
    fullName: yup.string().required("الاسم مطلوب."),
    phoneNumber: yup
      .string()
      .matches(/^05\d{8}$/, "يجب أن يبدأ رقم الجوال بـ 05 ويكون 10 أرقام.")
      .required("رقم الجوال مطلوب."),
    otp: yup
      .string()
      .length(5, "رمز التحقق يجب أن يكون 5 أرقام.")
      .when([], {
        is: () => context.showOtpField,
        then: yup.string().required("رمز التحقق مطلوب."),
      }),
    password: yup
      .string()
      .min(8, "كلمة المرور يجب أن تكون على الأقل 8 أحرف.")
      .max(22,"يجب أن تتكون كلمة المرور من 22 حرفًا على الأكثر")
      .required("كلمة المرور مطلوبة."),
  });
};
