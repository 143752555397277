import React, { useState } from "react";
import Footer from "./Footer";
import "./CoraPay.css";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useFormik } from "formik";
import { paymentMethodValidation } from "../validation/paymentMethodvalidation";
import { addPyamentMethod } from "../../services/add-payment-method.service";
import { toast } from "react-toastify";
import { fetchPaymentsMethods } from "../../services/fetch-payments-methods.service";
import Cookies from "js-cookie";

const Profile = () => {
  const [showBankForm, setShowBankForm] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [user, setUser] = useState({
    fullname: "",
    phoneNumber: "",
  });
  const retrieveUserData = () => {
    // Retrieve data from local storage
    const user = JSON.parse(localStorage.getItem("user"));
    const fullname = user.fullname || "ضيف";
    const phoneNumber = user.phoneNumber || "غير متوفر";
    setUser({ fullname, phoneNumber });
  };

  const fetchPaymentMethods = async () => {
    try {
      const response = await fetchPaymentsMethods();
      // Check if there are any payment methods
      setHasPaymentMethod(response.payments_method.length > 0);
    } catch (error) {
      toast.error("حدث خطأ أثناء التحقق من الحساب البنكي.");
    }
  };
  const formik = useFormik({
    initialValues: {
      full_name: "",
      saudi_id_number: "",
      bank_name: "",
      iban: "",
    },
    validationSchema: paymentMethodValidation,
    onSubmit: async (values, { resetForm }) => {
      try {
        await addPyamentMethod(values);
        toast.success("تمت إضافة الحساب البنكي بنجاح");
        setShowBankForm(false);
        resetForm();
        fetchPaymentMethods();
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "حدث خطأ أثناء إضافة الحساب البنكي.";
        toast.error(errorMessage);
      }
    },
  });
  useEffect(() => {
    retrieveUserData();
    fetchPaymentMethods();
  }, []);
  const handleLogout = () => {
    Cookies.remove("authToken", { domain: ".cora.sa", path: "/" });
    localStorage.clear();
    window.location.href = "/login";
  };
  return (
    <div className="CoraPay-main">
      <Helmet>
        <title>الصفحة الشخصية</title>
      </Helmet>

      <label className="CoraPay-label">مرحبا، {user.fullname}</label>

      <div className="Profile-options">
        <div
          className="Profile-option"
          onClick={() => {
            /* Navigate to settings */
          }}
        >
          <label className="Profile-label">{user.fullname}</label>
          <span>الاسم</span>
        </div>
        <div
          className="Profile-option"
          onClick={() => {
            /* Navigate to settings */
          }}
        >
          <span>البريد الالكتروني</span>
        </div>
        <div
          className="Profile-option"
          onClick={() => {
            /* Navigate to support */
          }}
        >
          <label className="Profile-label">{user.phoneNumber}</label>
          <span>رقم الجوال</span>
        </div>
        <div
          className="Profile-option"
          onClick={() => setShowBankForm(!showBankForm)}
        >
          <label
            className={`Profile-label ${
              hasPaymentMethod ? "active-status" : "unactive-status"
            }`}
          >
            {hasPaymentMethod ? "مضاف" : "غير مضاف"}
          </label>
          <span>الحساب البنكي</span>
        </div>
        {showBankForm && (
          <div className="Profile-bank-form">
            <form onSubmit={formik.handleSubmit}>
              <label>الاسم الكامل</label>
              <input
                type="text"
                name="full_name"
                value={formik.values.full_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.full_name && formik.errors.full_name && (
                <div className="text-red-600 text-sm">
                  {formik.errors.full_name}
                </div>
              )}
              <label>رقم الهوية</label>
              <input
                type="text"
                name="saudi_id_number"
                value={formik.values.saudi_id_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.saudi_id_number &&
                formik.errors.saudi_id_number && (
                  <div className="text-red-600 text-sm">
                    {formik.errors.saudi_id_number}
                  </div>
                )}

              <label>اسم البنك</label>
              <input
                type="text"
                name="bank_name"
                value={formik.values.bank_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.bank_name && formik.errors.bank_name && (
                <div className="text-red-600 text-sm">
                  {formik.errors.bank_name}
                </div>
              )}

              <label>IBAN</label>
              <input
                type="text"
                name="iban"
                value={formik.values.iban}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.iban && formik.errors.iban && (
                <div className="text-red-600 text-sm">{formik.errors.iban}</div>
              )}

              <button type="submit">إضافة</button>
            </form>
          </div>
        )}

        <div
          className="Pass-change"
          onClick={() => {
            /* Navigate to support */
          }}
        >
          <span>تغيير كلمة المرور</span>
        </div>
        <div className="logout" onClick={() => handleLogout()}>
          <span>تسجيل الخروج</span>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Profile;
