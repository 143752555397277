import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CoraPay.css';
import { Helmet } from 'react-helmet';


const ForgetPass = () => {
    const [step, setStep] = useState(1); // Step 1: Phone, Step 2: OTP, Step 3: New Password
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();

    const handleNextStep = () => {
        if (step === 1 && phoneNumber) setStep(2); // Move to OTP step
        else if (step === 2 && otp) setStep(3); // Move to New Password step
    };

    const handleSubmit = () => {
        navigate('/Registration');
    };

    return (
        <div className="CoraPay-main">

            <Helmet>
                <title>إعادة تعيين كلمة المرور</title>
            </Helmet>

            {step === 1 && (
                <>
                    <h2 className="ForgetPass-header">إعادة تعيين كلمة المرور</h2>
                    <div className="ForgetPass-form-group">
                        <label htmlFor="phoneNumber">رقم الجوال</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            placeholder="05xxxxxxxx"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="ForgetPass-input"
                            required
                        />
                    </div>
                    <button className="ForgetPass-button" onClick={handleNextStep}>
                        إرسال رمز التحقق
                    </button>
                </>
            )}

            {step === 2 && (
                <>
                    <h2 className="ForgetPass-header">رمز التحقق</h2>
                    <p className="ForgetPass-info">تم إرسال رمز التحقق إلى رقم الجوال</p>
                    <div className="ForgetPass-form-group">
                        <label htmlFor="otp">أدخل رمز التحقق</label>
                        <input
                            type="text"
                            id="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="ForgetPass-input"
                            required
                        />
                    </div>
                    <button className="ForgetPass-button" onClick={handleNextStep}>
                        تحقق
                    </button>
                </>
            )}

            {step === 3 && (
                <>
                    <h2 className="ForgetPass-header">إنشاء كلمة مرور جديدة</h2>
                    <div className="ForgetPass-form-group">
                        <label htmlFor="newPassword">كلمة المرور الجديدة</label>
                        <input
                            type="password"
                            id="newPassword"
                            placeholder="********"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="ForgetPass-input"
                            required
                        />
                    </div>
                    <div className="ForgetPass-form-group">
                        <label htmlFor="confirmPassword">تأكيد كلمة المرور</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            placeholder="********"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="ForgetPass-input"
                            required
                        />
                    </div>
                    <button className="ForgetPass-button" onClick={handleSubmit}>
                        تغيير كلمة المرور
                    </button>
                </>
            )}
        </div>
    );
};

export default ForgetPass;
